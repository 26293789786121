import React, { useState, useEffect } from 'react';
import { LangConsumer } from 'utils/LangProvider';
import { connect } from 'react-redux';
import { Row, Col, Button, Checkbox, message, Form, Input, Alert, Modal, Radio } from 'antd';
import { LabelInput, LabelSelect, LabelSwitch } from 'libs';
import { copy } from '../../../common';
import { lengthB } from 'utils/checkbit';
const transObj = {
    RoadFlowStat: 'ivs.TrafficMonitor',
    ObjectDetect: 'ivs.VideoStructrue',
    NumberStat: 'ivs.PtzNumberStat',
    HeatMap: 'ivs.HeatMap',
    CrowdDistriMap: 'com.CrowdDistriPic',
    VehiclesDistri: 'ivs.VehiclesDistri',
    SmartMotionHuman: 'ivs.SmartDetectionHuman',
    SmartMotionVehicle: 'ivs.SmartDetectionVehicle'
};
function FtpUploadComponent (props) {
    let {
        $t,
        FTPInfo,
        cycles,
        publicState: {
            getGrid
        },
        reportUploadTypes,
        ftpTest,
        visible
    } = props;
    const [loading, setLoading] = useState(false);
    const multiple = {
        type: 'normal',
        multiple: 1
    };
    useEffect(() => {
        let {
            getFtpData,
        } = props;
        getFtpData().catch(() => {
            message.error($t('com.Operateingfailure'));
        });
    }, []);
    const checkSavePath = (rule, value, callback) => {
        let { $t } = props;
        if (!value) {
            callback();
        }
        /*eslint-disable-next-line*/
        let ruleReg = /[^A-Za-z0-9_\-\/]+/;
        if (ruleReg.test(value)) {
            return callback($t('com.PathErrorTip'));
        }
        if (value.length > 64) {
            return callback($t('com.MaxLength'));
        }
        callback();
    };
    const changeFTPConfig = (name, value) => {
        const {FTPInfo = {}} = props;
        FTPInfo[name] = value;
    };
    /**
     * 校验ServerIP的IP，当ServerIP不为空时，需要为IPV4或者IPV6，且不能以127，0或者大于223开头
     * 第三个不能为0也不能大于254
     * @param {object} rule
     * @param {string} value 输入的值
     * @param {function} callback 回调函数
     */
    const checkServerIP = (rule, value, callback) => {
        //参考视图库页面代码 主动注册页面 AutoRegister
        let { $t, webCaps } = props;
        let ipRegOld = /^[\w-.]+$/;//原有判断逻辑，如果不支持ipv6能力集，则依然使用就的规则判断
        let ipRegNew = /^[\w-.:]+$/;
        let regExceptList = /(^(::)$)|(^(::1)$)|(^(FF))|(^(FE8))|(^(FE9))|(^(FEA))|(^(FEB))|(^(FC))|(^(FD))|(^(FEC))|(^(FED))|(^(FEE))|(^(FEF))/;
        //ipv4或者ipv6
        //TODO:如果支持ipv6
        if (webCaps.is_show_externalIpv6) {
            if (!ipRegNew.test(value) || regExceptList.test(value)) {
                return callback($t('com.ServerIpError'));
            }
        } else {
            if (!ipRegOld.test(value)) {
                return callback($t('com.EmailiperrorTip'));
            }
        }
        callback();
    };
    const handleChangeConfig = (name, value) => {
        let {
            FTPInfo,
            updateInfo
        } = props;

        FTPInfo[name] = value;
        updateInfo(FTPInfo);
    };
    const onFtpTest = () => {
        setLoading(true);
        ftpTest({name: 'ReportFtpUpload'}).then((json) => {
            const tipObj = {
                NoListAuth : 'com.FTPNoListAuthTip',
                NoDeleteFileAuth : 'com.FTPNoDeleteFileAuthTip',
                NoDeleteDirAuth : 'com.FTPNoDeleteDirAuthTip',
                ReadWrite : 'net.TestSuccess',
                ReadOnly : 'com.FTPReadOnlyTip',
                NotAcess : 'com.FTPNotAcessTip',
                LoginFailure : 'com.FTPLoginFailure'
            };
            let {
                access,
                authority,
                deleteDir,
                deleteFile,
                list
            } = json;
            if(access === 0) {
                if (authority === 'ReadWrite') {
                    if(list === 0) {
                        message.error($t(tipObj.NoListAuth));
                    } else {
                        if (deleteFile === 0) {
                            message.error($t(tipObj.NoDeleteFileAuth));
                        } else {
                            if(deleteDir === 0) {
                                message.error($t(tipObj.NoDeleteDirAuth));
                            } else {
                                message.success($t(tipObj.ReadWrite));
                            }
                        }
                    }
                } else if (authority === 'ReadOnly') {
                    message.error($t(tipObj.ReadOnly));
                }
            } else if(access === 1) {
                message.error($t(tipObj.NotAcess));
            } else if (access === 2) {
                message.error($t(tipObj.LoginFailure));
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };
    const { getFieldDecorator, validateFields, resetFields } = props.form;
    const uploadOptions = reportUploadTypes.map(item => {
        return {
            text: $t(transObj[item] || item),
            value: item
        };
    });
    /**
     * 确定，刷新，默认操作
     */
    const getFTPInfo = () => {
        let {
            FTPInfo: ftpConfig,
            form
        } = props;
        return new Promise((res, rej) => {
            form.validateFields((err, values) => {
                if (err) {
                    const key = Object.keys(err)[0] || '';
                    const errDesc = err[key].errors && err[key].errors[0] || {};
                    const errTip = errDesc.message;
                    message.error('FTP' + errTip);
                    rej(err);
                    return;
                }
                ftpConfig.Address = values.ServerIP;
                ftpConfig.Port = values.Port;
                ftpConfig.UserName = values.UserName;
                ftpConfig.Password = values.Password;
                ftpConfig.Directory = values.Directory;
                res(ftpConfig);
            });
        });
    };
    const onSubmit = () => {
        let { updateInfo, $t } = props;
        getFTPInfo().then((ftpConfig) => {
            updateInfo(ftpConfig);
            props.setFtpData().then(() => {
                message.success($t('com.Operateingsuccess'));
            }).catch(() => {
                message.error($t('com.Operateingfailure'));
            });
        });
    };
    const onRefresh = () => {
        let {
            getFtpData,
            form,
            $t
        } = props;
        form.resetFields();
        getFtpData().then(() => {
            message.success($t('com.Operateingsuccess'));
        }).catch(() => {
            message.error($t('com.Operateingfailure'));
        });
    };
    const onDefault = () => {
        let {
            getFtpDefault,
            form,
            $t
        } = props;
        form.resetFields();
        getFtpDefault().then(() => {
            message.success($t('com.Defaultsuccess'));
        }).catch(() => {
            message.error($t('com.Operateingfailure'));
        });
    };
    return (
        <div style={{display: visible ? '' : 'none'}}>
            <Form>
                <div className="fpt-upload-content">
                    <Row className='fn-marb15'>
                        <Col {...getGrid(multiple).container}>
                            <LabelSwitch
                                label={$t('com.Enable')}
                                checked={FTPInfo.Enable}
                                onChange={val => handleChangeConfig('Enable', val)}
                                colWidth={getGrid(multiple).colWidth}
                            />
                        </Col>
                    </Row>
                    <Row className='fn-marb15'>
                        <Col {...getGrid(multiple).container}>
                            <LabelSelect
                                label={$t('med.AppearCycle')}
                                options={cycles}
                                value={FTPInfo.Period}
                                onChange={val => handleChangeConfig('Period', val)}
                                colWidth={getGrid(multiple).colWidth}
                            />
                        </Col>
                    </Row>
                    <Row className='fn-marb15'>
                        <Col {...getGrid(multiple).container}>
                            <LabelSelect
                                label={$t('com.ReportType')}
                                options={uploadOptions}
                                value={FTPInfo.ReportType}
                                mode="multiple"
                                onChange={val => handleChangeConfig('ReportType', val)}
                                colWidth={getGrid(multiple).colWidth}
                            />
                        </Col>
                    </Row>
                    <Row className='list-item'>
                        <Col {...getGrid(multiple).container}>
                            <Row>
                                <Col span={24} className='labelCol textAlign'>
                                    <Form.Item>
                                        {getFieldDecorator('ServerIP', {
                                            rules: [
                                                {
                                                    validator: checkServerIP
                                                }
                                            ],
                                            initialValue: FTPInfo.Address
                                        })(
                                            <LabelInput label={$t('net.ServerIP')} className='label-input' onChange={(e) => changeFTPConfig('Address', e.target.value)} maxLength={64} colWidth={getGrid(multiple).colWidth} />
                                        )}

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='labelCol textAlign' >
                                    <Form.Item>
                                        {getFieldDecorator('Port', {
                                            initialValue: FTPInfo.Port
                                        })(
                                            <LabelInput label={$t('net.Port')} type='number' className='label-input' min={0} max={65535} precision={0} maxLength={5} onChange={(value) => changeFTPConfig('Port', value - 0)} colWidth={getGrid(multiple).colWidth} behind={'(0~65535)'} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} className='labelCol textAlign'>
                                    <Form.Item>
                                        {getFieldDecorator('UserName', {
                                            rules: [{
                                                pattern: /^[^'"’“]+$/,
                                                message: $t('com.NoInputMarksTip')
                                            }],
                                            initialValue: FTPInfo.UserName,
                                            normalize: (value, prevValue) => {
                                                if (value && lengthB(value) > 32) {
                                                    return prevValue;
                                                } else {
                                                    return value;
                                                }
                                            }
                                        })(
                                            <LabelInput label={$t('com.Username')} className='label-input' onChange={(e) => changeFTPConfig('UserName', e.target.value)} maxLength={32} colWidth={getGrid(multiple).colWidth} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} className='labelCol textAlign'>
                                    <Form.Item >
                                        {getFieldDecorator('Password', {
                                            rules: [{
                                                pattern: /^[^'"’“]+$/,
                                                message: $t('com.NoInputMarksTip')
                                            }],
                                            initialValue: FTPInfo.Password || '******'
                                        })(
                                            <LabelInput label={$t('com.Password')} className='label-input' onChange={(val) => {changeFTPConfig('Password', val);}} maxLength={32} type='password' colWidth={getGrid(multiple).colWidth} pswMaskTip = {$t('com.ReservedMaskPwdTip')}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} className='labelCol textAlign'>
                                    <Form.Item>
                                        {getFieldDecorator('Directory', {
                                            rules: [{ validator: checkSavePath }],
                                            initialValue: FTPInfo.Directory
                                        })(
                                            <LabelInput label={$t('com.Savepath')} className='label-input' onChange={(e) => changeFTPConfig('Directory', e.target.value)} maxLength={32} colWidth={getGrid(multiple).colWidth} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="operation fn-width200 fn-margin-top10 btn-margin">
                                <Row>
                                    <Col {...getGrid(multiple).label}></Col>
                                    <Col {...getGrid(multiple).body}>
                                        <Button loading={loading} onClick={onFtpTest} className='fn-margin-right10'>{$t('com.Test')}</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div className='fn-mart10'>
                        <Button type='primary' className='fn-marr10' onClick={() => onSubmit()}>{$t('com.Apply')}</Button>
                        <Button onClick={() => onRefresh()} className='fn-marr10'>{$t('com.Refresh')}</Button>
                        <Button onClick={() => onDefault()} >{$t('com.Comdefault')}</Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}

FtpUploadComponent.propTypes = {
};

export default Form.create({name: 'FTPupload'})(FtpUploadComponent);